<div class="program-heading-row">
    <div class="program-module-title">Programs</div>
    <button [disabled]="isCreateProgramButtonDisabled || !isExistingProgramNamesUpdated" class="create-program-button" mat-raised-button color="primary" (click)="openPatientInformationDialog()">Create a
        Program</button>

    <!-- This button is to delete programs, for testing purpose and demo  -->
    <!-- <button style="float: right;" mat-raised-button color="primary" (click)="deletePrograms()">
        Delete Program</button> -->
    <!-- This button is to delete Goals, for testing purpose and demo  -->
    <!-- <button style="float: right;" mat-raised-button color="primary" (click)="deleteGoals()">
        Delete Goals</button> -->
    <!-- This button is to delete Patients, for testing purpose and demo  -->
    <!-- <button style="float: right;" mat-raised-button color="primary" (click)="deletePatients()">
      Delete Patients</button> -->
    <!-- This button is to delete Tasks, for testing purpose and demo  -->
    <!-- <button style="float: right;" mat-raised-button color="primary" (click)="deleteTasks()">
      Delete Tasks</button> -->
    <!-- This button is to delete CarePlans, for testing purpose and demo  -->
    <!-- <button style="float: right;" mat-raised-button color="primary" (click)="deleteCarePlans()">
      Delete CarePlans</button> -->
</div>
<program-app-program-list 
  [viewOnly]="viewOnly"
  [goals]="goals" 
  (resetGoal)="getGoals()"
  [showSpinner]="showSpinnerInChild"
  [allPrograms]="allPrograms" 
  [existingProgramNames]="existingProgramNames"></program-app-program-list>
<router-outlet>
</router-outlet>

<section *ngIf="isShowLoadingSectionVisible" class="show-loading">
  <mat-spinner class="patients-rpm-spinner" [strokeWidth]="defaultSpinnerProperties.strokeWidth"
    [diameter]="defaultSpinnerProperties.diameter"></mat-spinner>
</section>