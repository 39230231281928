import { sortBy } from 'lodash';
import { ContactPoint, Goal } from 'fhir/r4';
import { Subject, takeUntil } from 'rxjs';

import { Component, OnInit, Inject, OnDestroy, HostBinding } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { GoalCodes, GoalDecimalInput, METRIC_CODE_UNIT_MAP } from 'src/app/config/app.config';
import { GoalsMinMaxValues } from 'src/app/config/app.config';
import { CreateProgramDialogData } from 'src/app/models/matDialogData.model';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CustomBreakpointState } from 'src/app/shared/interfaces/custom_breakpoint_state_interface';
import { rearrangeBloodPressureGoals } from 'src/app/utils/shared.util';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';

@Component({
  selector: 'program-app-create-program',
  templateUrl: './create-program.component.html',
  styleUrls: ['./create-program.component.scss']
})
export class CreateProgramComponent implements OnInit, OnDestroy {
  formGroup: FormGroup = <FormGroup>{};
  displayedColumns = ['metricName', 'lowValue', 'highValue', 'action'];
  dataSource = new MatTableDataSource<any>();
  // selected program's preset goals: rearranged to show blood pressure goals last
  presetGoals: Goal[] = [];
  allGoals: Goal[] = [];
  // add metrics dropdown's selected goals: rearranged to show blood pressure goals last
  selectedGoals: Goal[] = [];
  isRenderForm = false;
  previousPresetGoalValues: Array<{ low: number, high: number } | null> = [];
  unsubscribe$: Subject<void> = new Subject();
  goalsMinMaxValues = GoalsMinMaxValues;
  bloodPressureCode = GoalCodes.BLOOD_PRESSURE;
  isBloodPressureSelected = false;
  allProgramNames!: string[];
  //#R2-479 - handled editing threshold valu of prg after publish
  isPublished = false;

  separatorKeysCodesForEmailInput = [ENTER, COMMA, SPACE];
  emailList: string[] = [];
  private emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  /** Used for setting as xlarge screen for responsiveness */
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: CreateProgramDialogData,
    private dialogRef: MatDialogRef<CreateProgramComponent>,
    private formBuilder: FormBuilder,
    private breakpointService: BreakpointService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    const { rowData, goals = [], viewProgram = false, editProgram = false, existingProgramNames = [] } = this.dialogData;
    
    this.allProgramNames = editProgram
      ? existingProgramNames.filter((programName: string) => programName !== rowData.title.toLowerCase())
      : existingProgramNames;

    // hide action column in view mode
    if (viewProgram) {
      this.displayedColumns.length = 3;
    }

    // populate mat select dropdown
    this.allGoals = sortBy(goals, rearrangeBloodPressureGoals);

    // populate table form
    this.presetGoals = editProgram || viewProgram
      ? sortBy(rowData.goal, rearrangeBloodPressureGoals)
      : [];
    
    // set isBloodPressureSelected when editing programs
    // to toggle selected status of both blood pressure goals in add metrics dropdown
    if (editProgram) {
      //#R2-479 - handled editing threshold valu of prg after publish
      this.isPublished = this.dialogData.rowData.status === 'active';
      this.isBloodPressureSelected = !!this.presetGoals.find((goal: Goal) => goal.target?.[0].measure?.coding?.[0].code === this.bloodPressureCode);
    }
    
    this.buildDynamicModalForm();
    
    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: CustomBreakpointState) => {
        this.xlarge = value.XLarge;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });
  }

  buildDynamicModalForm() {
    const { title = '', description = '', goal = [], contact = [] } = this.dialogData?.rowData ?? {};
    const metricUniqueCodes = goal.map(({ target }: any) => target[0].measure.coding[0].code);
    // to check pre-selected goals in goals dropdown, get pre-selected goal ids
    // by comparing goal's target code against all target codes in available goals
    // target[0].measure.coding[0].code = unique code for specific goal except blood pressure systolic/diastolic goals
    const preSelectedGoals: any[] = this.dialogData?.editProgram
      ? this.allGoals
        .map((goal: any) => metricUniqueCodes.includes(goal.target[0].measure.coding[0].code) ? goal : null)
        .filter(Boolean)
      : [];
    this.formGroup = this.formBuilder.group({
      tableForm: this.formBuilder.array([]),
    });
    this.formGroup = this.formBuilder.group({
      programName: [title ?? '', [
          Validators.required,
          this.validateProgramNameUniqueness.bind(this)
        ]
      ],
      email: ['', Validators.required ],
      programDescription: [description ?? '', Validators.required],
      ...(!this.dialogData?.viewProgram && {
        addMetrics: [
          this.dialogData?.editProgram
            ? preSelectedGoals
            : [],
          Validators.required
        ]
      })
    });
    this.emailList = this.getProgramEmail(contact);
    if (this.emailList.length) {
      this.formGroup.get('email')?.setErrors(null);
      this.formGroup.get('email')?.setValue(this.emailList);
    }
    // Jira bug fix #R2-396
    this.selectedGoals = preSelectedGoals; 
    // update goals dropdown checked items 
    this.updatePresetGoalsUI();
  }

  private getProgramEmail(contact: any): string[] {
    if (!contact.length || !contact[0]?.telecom?.length) {
      return [];
    }
    return contact[0].telecom
      .map((contactPoint: ContactPoint) => contactPoint?.system === 'email'
        ? contactPoint.value || ''
        : ''
      )
     .filter(Boolean);
  }

  getTableForm() {
    return this.formGroup.controls?.['tableForm'] as FormArray;
  }

  updatePresetGoalsUI() {
    this.previousPresetGoalValues.length = 0;
    this.formGroup.removeControl('tableForm');
    this.formGroup.addControl('tableForm', this.formBuilder.array(
      this.presetGoals?.length
        ? this.presetGoals
          .flatMap((goal: any) => {
            const { id: goalId, target, description: { text: metricName } } = goal;
            if (!goalId) {
              throw new Error('Goal id not accessible');
            }
            return target.map(({ detailRange = {}, measure = {} }: any) => {
              if (!measure?.coding || !measure?.coding?.length) {
                throw new Error('Goal\'s coding is not specified');
              }
              const { code: goalUniqueCode } = measure.coding[0];
              this.previousPresetGoalValues.push(null);
              const metricSystemCode: string = measure.coding[0].code || '';
              const unit = metricSystemCode ? METRIC_CODE_UNIT_MAP.find((item) => item.code === metricSystemCode)?.unit : '';
              return this.formBuilder.group({
                metricName: new FormControl(metricName),
                low: new FormControl(detailRange?.low?.value ?? '', [Validators.required, this.getGoalDecimalInput(goalUniqueCode)]),
                high: new FormControl(detailRange?.high?.value ?? '', [Validators.required, this.getGoalDecimalInput(goalUniqueCode)]),
                isEditable: new FormControl({ value: false, disabled: false }),
                code: new FormControl(unit || ''),
                goalId: new FormControl(goalId),
                goalUniqueCode: new FormControl(goalUniqueCode),
              })
            })
          })
        : []
    ));
    this.dataSource = new MatTableDataSource((this.formGroup.get('tableForm') as FormArray).controls);
    this.isRenderForm = true;
  }

  // R2-1093
  private getGoalDecimalInput(goalUniqueCode: string): ValidatorFn {
    return GoalDecimalInput[goalUniqueCode]
      ? Validators.pattern(/^[0-9.]+$/)
      : Validators.pattern(/^[0-9]+$/);
  }

  onSaveAsDraft(): void {
    // #R2-522 - bug fix
    const error = this.validateAllMetricsThresholdValue();
    if (error) {
      this.notificationService.showNotification(error.message || "Invalid inputs", 'error', 'Ok');
      return;
    }
    this.dialogRef.close({
      formValue: this.formGroup.value,
      presetMetrics: this.presetGoals,
      status: 'draft',
      editProgram: this.dialogData?.editProgram
    });
  }

  onSaveAsPublish(): void {
    // #R2-522 - bug fix
    const error = this.validateAllMetricsThresholdValue();
    if (error) {
      this.notificationService.showNotification(error.message || "Invalid inputs", 'error', 'Ok');
      return;
    }
    this.dialogRef.close({
      formValue: this.formGroup.value,
      presetMetrics: this.presetGoals,
      status: 'active',
      editProgram: this.dialogData?.editProgram
    });
  }

  onEditRow(formGroup: any, index: number) {
    const { low, high } = formGroup.get('tableForm').at(index).value;
    this.previousPresetGoalValues.splice(index, 0, { low, high });
    formGroup.get('tableForm').at(index).get('isEditable').patchValue(true);
  }

  /**
   * A goal's min and max value is stored as config property in frontend 
   * since it's not available in goal's FHIR response with on-hold status
   */
  onSaveRow(formGroup: any, index: number) {
    const { low, high } = formGroup.get('tableForm').at(index).value;
    // #R2-522 - bug fix
    const error = this.validateThresholdValue(formGroup.get('tableForm').at(index).value)
    if (error.hasError) {
      this.notificationService.showNotification(error.message || "Invalid inputs", 'error', 'Ok');
      return;
    }
    /**
     * #R2-543 - bug fix - need to persue modified value and
     * #R2-523 - bug fix - the modified values shouldn't bind to actual program goals value
     */
    this.presetGoals = this.presetGoals.map((goal, goalIndex) => {
      if (goalIndex === index) {
        const { target } = goal;
        return {
          ...goal,
          target: [
            {
              ...target?.[0],
              detailRange: {
                low: {
                  ...target?.[0].detailRange?.low,
                  value: low,
                },
                high: {
                  ...target?.[0].detailRange?.high,
                  value: high,
                }
              }
            }
          ]
        }
      }
      return goal;
    });
    formGroup.get('tableForm').at(index).get('isEditable').patchValue(false);
  }

  /**
   * To validate all the threshold values in the tableForm
   */
  validateAllMetricsThresholdValue(): { message?: string, hasError: boolean } | undefined {
    return (this.formGroup.get('tableForm') as FormArray).controls
      .map(control => this.validateThresholdValue(control.value))
      .find(({ hasError }) => hasError);
  }

   /**
   * To validate threshold values of specific metrics on update row in tableForm
   */
  validateThresholdValue({ low, high, goalUniqueCode, metricName } : { 
    low: string,
    high: string,
    goalUniqueCode: string,
    metricName: string
  }): {
    message?: string,
    hasError: boolean
  } {
    const currentGoalMinMaxValues = this.goalsMinMaxValues[goalUniqueCode];
    if (!currentGoalMinMaxValues) {
      return {
        hasError: true,
        message: "Goal\'s min max values not available"
      };
    }
    const { low: currentGoalLow, high: currentGoalHigh } = currentGoalMinMaxValues;
    if (low < currentGoalLow) {
      return {
        hasError: true,
        message: `${metricName} low value is lower than min value of ${currentGoalLow}`
      };
    }
    if (high > currentGoalHigh) {
      return {
        hasError: true,
        message: `${metricName} high value is higher than max value of ${currentGoalHigh}`
      };
    }
    if (+high < +low) {
      return {
        hasError: true,
        message: `Low value can\'t be higher than high value for ${metricName}`
      };
    }
    return { hasError: false };
  }

  onCancelRow(formGroup: any, index: number) {
    formGroup.get('tableForm').at(index).patchValue(this.previousPresetGoalValues[index]);
    formGroup.get('tableForm').at(index).get('isEditable').patchValue(false);
  }

  onGoalsAdded(): void {
    if (!this.selectedGoals.length) {
      this.presetGoals = [];
      this.updatePresetGoalsUI();
      return;
    };
    const presetGoalsRef: Array<{ code: string, system: string, presetGoal: any }> = this.presetGoals
      .map((goal: any) => {
        const { code, system } = goal.target[0].measure.coding[0];
        return { code, system, presetGoal: goal };
      });
    const selectedGoals = this.selectedGoals
      .map((goal: any) => {
        const { code: selectedCode, system: selectedSystem } = goal.target[0].measure.coding[0];
        /*
         * #R2-209 - Fixed repeating blood pressure metrics
         * blood pressure has 2 metrics sys & dia but the code is same for both metrics,
         * so here matching with description text as well for not to repeating
        */
        const isGoalInPresetGoals = presetGoalsRef.findIndex(({ code, system, presetGoal }) => selectedCode === code && selectedSystem === system && (code === this.bloodPressureCode ? presetGoal.description.text !== goal.description.text : true));
        return isGoalInPresetGoals > -1
          ? presetGoalsRef[isGoalInPresetGoals].presetGoal
          : goal
      });
    this.presetGoals = sortBy(selectedGoals, rearrangeBloodPressureGoals);
    this.updatePresetGoalsUI();
  }

  
  findBloodPressure = (goal: Goal) => {
    return goal.target?.[0].measure?.coding?.[0].code === this.bloodPressureCode;
  }

  handleGoalClick(goal: Goal) {
    if (goal.target?.[0].measure?.coding?.[0].code === this.bloodPressureCode) {
      if (this.isBloodPressureSelected) {
        const updatedGoals = this.selectedGoals.filter((goal: Goal) => {
          return !this.findBloodPressure(goal);
        })
        this.selectedGoals = [
          ...updatedGoals
        ];
        this.isBloodPressureSelected = false;
        return;
      }

      const otherBloodPressure = this.allGoals.find((existingGoal: Goal) => {
        return (
          existingGoal.target?.[0].measure?.coding?.[0].code === goal.target?.[0].measure?.coding?.[0].code
          &&
          existingGoal.description.text !== goal.description.text
        )
      });
      if (otherBloodPressure) {
        this.isBloodPressureSelected = true;
        this.selectedGoals = sortBy([...this.selectedGoals, otherBloodPressure], rearrangeBloodPressureGoals);
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private validateProgramNameUniqueness(control: AbstractControl): ValidationErrors | null {
    return this.allProgramNames?.includes(control.value.trim().toLowerCase())
      ? { programNameExists: true }
      : null;
  }

  addEmail(event: any): void {
    if (event.value === '') {
      const validity = this.emailList.every((email: string) => this.emailRegex.test(email))
        ? null
        : { 'invalidEmail': true };
      const requiredStatus = this.emailList.length
        ? null
        : { 'required': true };
      this.formGroup.get('email')?.setErrors(validity ?? requiredStatus);
      return;
    }
    if (this.emailList.includes(event.value)) {
      event.input.value = '';
      return;
    }
    if (event.value && this.emailRegex.test(event.value)) {
      this.emailList.push(event.value);
      event.input.value = '';
      this.formGroup.get('email')?.setErrors(null);
      this.formGroup.get('email')?.setValue(this.emailList);
      return;
    }
    if (event.value) {
      this.formGroup.get('email')?.setErrors({ 'invalidEmail': true });
    }
  }

  removeEmail(data: any): void {
    if (this.emailList.indexOf(data) >= 0) {
      this.emailList.splice(this.emailList.indexOf(data), 1);

      if (!this.emailList.length) {
        this.formGroup.get('email')?.setErrors({ 'required': true });
      }
    }
  }
}
