import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ShowLoaderService {
  /**
   * Setting the value to true will show the global loader.
   */
  isShowLoadingSectionVisible$ = new BehaviorSubject<boolean>(false);

  constructor() { }
}
