import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Bundle, CarePlan, Goal, Patient, Task } from 'fhir/r4';
import { map, Observable, tap } from 'rxjs';
import { DefaultResourceQueryCount } from '../config/app.config';
import { BaseService } from './base-api.service';


@Injectable({
  providedIn: 'root'
})
export class GoalService extends BaseService {

  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  getGoals(): Observable<Bundle<Goal>> {
    console.log("GetGoals called !");
    return this.http.get<Bundle<Goal>>(`${this.FHIR_BASE}/Goal?lifecycle-status=on-hold&_total=accurate&_count=${DefaultResourceQueryCount}`);
  }

  /**
   * Function used to delete all goals except ones with status on-hold. 
   * This function is only used for deleting goals for demo, 
   * TODO: Delete this Function !
   * @returns 
   */
  deleteGoals() {
    return this.http.get<Bundle<Goal>>(`${this.FHIR_BASE}/Goal?lifecycle-status:not=on-hold&_count=${DefaultResourceQueryCount}`)
      .pipe(
        tap((response: any) => {
          console.log("Delete Goals list : ", response.entry);
        }),
        map((response: any) => {
          response.entry.map((item: any) => {
            this.http.delete(`${this.FHIR_BASE}/Goal/${item.resource.id}`).subscribe();
          })
        })

      )
  }

  /**
   * Function used to delete all patients
   * This function is only used for deleting goals for demo, 
   * TODO: Delete this Function !
   * @returns 
   */
  deletePatients() {
    return this.http.get<Bundle<Patient>>(`${this.FHIR_BASE}/Patient?_total=accurate&_count=${DefaultResourceQueryCount}`)
      .pipe(
        tap((response: any) => {
          console.log("Delete Patients list : ", response.entry);
        }),
        map((response: any) => {
          response.entry.map((item: any) => {
            this.http.delete(`${this.FHIR_BASE}/Patient/${item.resource.id}`).subscribe();
          })
        })
      )
  }

  deleteTasks() {
    return this.http.get<Bundle<Task>>(`${this.FHIR_BASE}/Task?_total=accurate&_count=${DefaultResourceQueryCount}`)
      .pipe(
        tap((response: any) => {
          console.log("Delete Tasks list : ", response.entry);
        }),
        map((response: any) => {
          response.entry.map((item: any) => {
            this.http.delete(`${this.FHIR_BASE}/Task/${item.resource.id}`).subscribe();
          })
        })
      )
  }

  deleteCarePlans() {
    return this.http.get<Bundle<CarePlan>>(`${this.FHIR_BASE}/CarePlan?_total=accurate&_count=${DefaultResourceQueryCount}`)
      .pipe(
        tap((response: any) => {
          console.log("Delete CarePlan list : ", response.entry);
        }),
        map((response: any) => {
          response.entry.map((item: any) => {
            this.http.delete(`${this.FHIR_BASE}/CarePlan/${item.resource.id}`).subscribe();
          })
        })
      )
  }
}
