import { Goal } from "fhir/r4";
import { BloodPressureTexts } from "../config/app.config";
import { IIdTokenClaims, IIdTokenClaimsParsed } from "../shared/interfaces/id_token_claims";

export function parseIdTokenClaims(idTokenClaims: IIdTokenClaims): IIdTokenClaimsParsed {
  return {
    ...idTokenClaims,
    appClaims: JSON.parse(idTokenClaims.appClaims),
    appRole: JSON.parse(idTokenClaims.appRole)
  }
}

/**
 * Rearrange goals array with blood pressure systolic and blood pressure diastolic goals at the end of the array
 * @param goal goal array with blood pressure goals
 * @returns sorted array with blood pressure systolic and blood pressure diastolic at the end of the array
 */
export const rearrangeBloodPressureGoals = (goal: Goal) => {
  const goalTitle = goal?.description?.text?.toLowerCase();
  switch (goalTitle) {
    case BloodPressureTexts.systolic:
      return 1;
    case BloodPressureTexts.diastolic:
      return 2;
    default:
      return 0;
  }
};