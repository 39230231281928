<div class="program-list-example-container mat-elevation-z8">
  <mat-table #table [dataSource]="dataSource" matSort matSortDisableClear multiTemplateDataRows
    [ngClass]="{'program-list-custom-table-min-height':!showSpinner && dataSource.data.length}">

    <!-- title -->
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Program Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> 
        <mat-icon class="program-expanded">keyboard_arrow_up</mat-icon>
        <mat-icon class="program-shrinked">keyboard_arrow_down</mat-icon>
        {{element.title }} 
      </mat-cell>
    </ng-container>

    <!-- description -->
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Program Description </mat-header-cell>
      <mat-cell *matCellDef="let element" [matTooltip]="element.description"> {{element.description | slice:0:20}}</mat-cell>
    </ng-container>

    <!-- status -->
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="status"> Status
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="status"> {{element.status === 'active' ?
        'Published' : element.status | titlecase}}
      </mat-cell>
    </ng-container>

    <!-- status -->
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef class="program-list-action-column-header"> Action </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = dataIndex;" class="program-list-action-column">
        <button *ngIf="element.status === 'draft' || element.status === 'active'" mat-button class="program-list-action-button"
          (click)="onEditProgram($event, element)">
          <mat-icon class="program-list-icon-display">edit</mat-icon>
        </button>
        <button *ngIf="element.status === 'draft'" mat-button class="program-list-action-button"
          (click)="onDeleteProgram($event, element)">
          <mat-icon class="program-list-icon-display">delete_forever</mat-icon>
        </button>
        <button *ngIf="element.status === 'active'" mat-button class="program-list-action-button"
          (click)="onViewProgram($event, element)">
          <mat-icon class="program-list-icon-display">remove_red_eye</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <!-- expanded description -->
    <ng-template #tpl let-element>
      <div class="mat-row program-list-detail-row" [@detailExpand] style="overflow: hidden">
        <h3 class="program-list-description-heading">
          Program Description
        </h3>
        <p class="program-list-description-text">
          {{element.description}}
        </p>
      </div>
    </ng-template>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="program-list-element-row"
      [programAppCdkDetailRow]="row" [programAppCdkDetailRowTpl]="tpl">
    </mat-row>
  </mat-table>

  <section *ngIf="!showSpinner && !dataSource.data.length" class="program-no-mat-table-data">
    No programs found!
  </section>

  <section *ngIf="showSpinner" class="program-no-mat-table-data">
    <mat-spinner class="program-rpm-spinner" [diameter]="spinnerProps.diameter"
      [strokeWidth]="spinnerProps.strokeWidth"></mat-spinner>
  </section>

  <mat-paginator [pageSizeOptions]="paginationSizes" (page)="onChangeItemPerPage()"
    [ngClass]="{'retina':retina, 'retina-xdr':retinaXdr,'retina-4k':retina4k}"></mat-paginator>

</div>