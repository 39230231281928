import { Goal } from "fhir/r4";
import ShortUniqueId from 'short-unique-id';

const uid = new ShortUniqueId({ length: 12 });


export const MapUpdatedGoals = (formValue: any, presetGoals: Goal[]) => {
  return formValue.tableForm.map((metric: any) => {
    const mappedGoal: any = presetGoals.find((m: any) => m.id === metric.goalId);
    if (!mappedGoal) {
      throw new Error('Goal not found');
    }
    mappedGoal.id = uid();
    mappedGoal.target[0].id = uid();
    mappedGoal.target[0]['detailRange'].low.value = metric?.low || '';
    mappedGoal.target[0]['detailRange'].high.value = metric?.high || '';
    const { resourceType, meta, subject, lifecycleStatus, ...rest } = mappedGoal;
    return rest;
  });
}