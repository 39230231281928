<div class="text-container">
    <div class="text-row">
        <mat-icon aria-hidden="false" aria-label="success" fontIcon="check" *ngIf="data.notificationType === 'success' "
            class="snackbar-icon"></mat-icon>
        <mat-icon aria-hidden="false" aria-label="success" fontIcon="cancel" *ngIf="data.notificationType === 'error' "
            class="snackbar-icon"></mat-icon>
        <p class="snackbar-message">
            {{data.message}}
        </p>
    </div>
    <div class="button-row" *ngIf="data.buttonText">
        <button mat-flat-button (click)="snackBarRef.dismiss()">{{data.buttonText}} </button>
    </div>
</div>