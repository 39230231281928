import { Bundle, BundleEntry } from "fhir/r4";
import { ActionForProgram } from "../models/calcom.model";

const actionPayloadForProgram: ActionForProgram[] = [
  {
    title: "Alert for Follow-Up Virtual Visit",
    description: "An alert action to schedule a follow-up virtual visit with the patient.",
    code: [
      {
        coding: [
          {
            system: "https://api.cal.com",
            // set calcom team id as code here
            code: ""
          },
          {
            code: "virtual-consult",
            display: "Virtual Consultation"
          }
        ]
      }
    ],
    dynamicValue: [
      {
        path: "scheduling-type",
        expression: {
          language: "text/fhirpath",
          expression: "'round-robin'"
        }
      },
      {
        path: "self-schedule",
        expression: {
          language: "text/fhirpath",
          expression: "'yes/no'"
        }
      }
    ]
  }
];

/**
 * Set calcom team id as code in ActionForProgram[]
 */
export const MapActionToProgram = (teamId: string): ActionForProgram[] => { 
  actionPayloadForProgram[0].code[0].coding[0].code = teamId;
  return actionPayloadForProgram;
}

/**
 * Extracting FHIR Bundle to specified resource type array
 * @param bundle Bundle containing resource type T
 * @returns Array containing resource objects of type T
 */
export const MapBundleToResourceArray = <T>(bundle: Bundle<T>): T[] => {
  return bundle?.entry?.length
    ? bundle.entry
      .filter((bundleEntry: any) => !bundleEntry.resource?.issue)
      .map((bundleEntry: BundleEntry<T>) => bundleEntry.resource!)
    : [];
}