import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalcomService extends BaseService {
  
  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  createTeam(programName: string): Observable<any> {
    const createTeam = {
      name: programName,
      // slug should be unique
      slug: programName,
      hideBookATeamMember: false,
      brandColor: "#2797b6",
      darkBrandColor: "#2797b6",
      timeZone: "America/Toronto",
      weekStart: "Monday",
      isPrivate: false
    };
    return this.http.post(`${this.VIRTUAL_EVENTS}/teams`, createTeam);
  }
}
