/**
 * Common config items
 */
export const RPM_DATE_FORMAT = 'YYYY-MM-DD';

// confirm dialog properties
export const DialogResponsiveWidth = {
  medium: '750px',
  tablet: '750px',
  large: '750px',
  xlarge: '750px',
  retina: '900px',
  retinaXdr: '1181.25px',
  retina4k: '1600px',
}

export const DefaultDialogProperties = {
  width: DialogResponsiveWidth.large, //default to '750px',
  disableClose: true,
  panelClass: 'custom-dialog-container',
};

export const notificationDuration = 3000;

export const MatTableDefaultPageSizes = [10, 20, 30, 40];


export const spinnerProperties = {
  diameter: 50,
  strokeWidth: 2.5
}

export const customBreakpoints = {
  XSmall: '(max-width: 599px)',
  Small: '(min-width: 600px) and (max-width: 959px)',
  Medium: '(min-width: 960px) and (max-width: 1279px)',
  Tablet: '(min-width: 600px) and (max-width: 1279px)',
  Large: '(min-width: 1280px) and (max-width: 1919px)',
  XLarge: '(min-width: 1920px) and (max-width:2303px)',
  Retina: '(min-width: 2304px) and (max-width:3023px)',
  RetinaXDR: '(min-width: 3024px) and (max-width:4095px)',
  Retina4k: '(min-width: 4096px) and (max-width:5119px)',
  Retina5k: '(min-width: 5120px)',
};

export const DefaultResourceQueryCount = 1000;

export const METRIC_CODE_UNIT_MAP = [
  {
    code: '29463-7',
    title: 'Weight',
    unit: 'lbs'
  },
  {
    code: '15074-8',
    title: 'Glucometer',
    unit: 'mmol/L'
  },
  {
    code: '59408-5',
    title: 'Pulseoximeter',
    unit: '%'
  },
  {
    code: '8867-4',
    title: 'Heartrate',
    unit: 'bpm'
  },
  {
    code: '85354-9',
    title: 'Bloodpressure',
    unit: 'mmHg'
  },
  {
    code: '8310-5',
    title: 'Temperature',
    unit: 'C'
  }]
export const GoalsMinMaxValues: any = {
  // weight
  '29463-7': {
    low: 50,
    high: 500,
  },
  // glucometer
  '15074-8': {
    low: 0,
    high: 30,
  },
  // pulseoximeter
  '59408-5': {
    low: 30,
    high: 100,
  },
  // heartrate
  '8867-4': {
    low: 20,
    high: 300,
  },
  // blood pressure systolic/blood pressure diastolic
  '85354-9': {
    low: 20,
    high: 500,
  },
  // temperature
  '8310-5': {
    low: 20,
    high: 60,
  },
}

export const GoalCodes = {
  WEIGHT: '29463-7',
  GLUCOMETER: '15074-8',
  PULSE_OXIMETER: '59408-5',
  HEART_RATE: '8867-4',
  BLOOD_PRESSURE: '85354-9',
  TEMPERATURE: '8310-5'
}

// R2-1093
export const GoalDecimalInput: { [key: string]: boolean } = {
  // weight
  '29463-7': true,
  // temperature
  '8310-5': true,
  // blood glucose
  '15074-8': true,
  // spo2
  '59408-5': false,
  // blood pressure
  '85354-9': false,
  // heart rate
  '8867-4': false,
}

export enum BloodPressureTexts {
  systolic = 'blood pressure systolic',
  diastolic = 'blood pressure diastolic',
}

export const INACTIVE_DURATION = 10 * 60 * 1000; // 10 minutes; 

export const ORG_PERSIST_KEY = 'activeOrganization';

export interface ApiConfig {
  fhirBase: string;
  virtualEvents: string;
}