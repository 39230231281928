<div class="create-program-title-row">
  <h2 mat-dialog-title class="create-program-custom-dialog-title">
    {{dialogData?.viewProgram ? 'View' : dialogData?.editProgram ? 'Edit' : 'Create'}} Program
  </h2>
  <mat-icon class="create-program-pointer" [mat-dialog-close]="false">close</mat-icon>
</div>
<mat-dialog-content style="overflow-x: hidden;">
  <ng-container *ngIf="isRenderForm">
    <form [formGroup]="formGroup" autocomplete="off">
      <!-- program name -->
      <span class="mat-h4 create-program-input-label">Program Name *</span>
      <mat-form-field class="program-rpm-input">
        <input matInput formControlName="programName" required [class.disabled]="dialogData?.viewProgram || isPublished"
          [readonly]="dialogData?.viewProgram || isPublished">
        <mat-error *ngIf="formGroup.get('programName')?.hasError('required')">
          Program name is required.
        </mat-error>
        <mat-error *ngIf="formGroup.get('programName')?.hasError('programNameExists')">
          Program name already exists.
        </mat-error>
      </mat-form-field>

      <!-- program description -->
      <span class="mat-h4 create-program-input-label">Program Description *</span>
      <mat-form-field>
        <textarea matInput formControlName="programDescription" rows="8" [class.disabled]="dialogData?.viewProgram || isPublished"
          [readonly]="dialogData?.viewProgram || isPublished"></textarea>
        <mat-error *ngIf="formGroup.get('programDescription')?.hasError('required')">
          Program Description is required.
        </mat-error>
      </mat-form-field>

      <!-- add metrics -->
      <span *ngIf="!dialogData?.viewProgram" class="mat-h4 create-program-input-label">Add Metrics *</span>
      <mat-form-field *ngIf="!dialogData?.viewProgram && !isPublished" class="program-rpm-input">
        <mat-select formControlName="addMetrics" multiple class="create-program-select-metrics"
          (closed)="onGoalsAdded()"
          [(value)]="selectedGoals">
          <mat-option *ngFor="let metric of allGoals" [value]="metric" (click)="handleGoalClick(metric)">{{metric.description.text}}</mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.get('addMetrics')?.hasError('required')">
          Please select at least one metric that needs to be monitored.
        </mat-error>
      </mat-form-field>

      <!-- monitored metrics table -->
      <!-- <h4 mat-dialog-title style="margin: 10px 0; font-size: 14px; font-weight: bold;">Preset Threshold</h4> -->
      <ng-container formArrayName="tableForm">

        <section class="create-program-table-container">
          <table mat-table [dataSource]="dataSource">
            <!-- metrics name -->
            <ng-container matColumnDef="metricName">
              <th mat-header-cell *matHeaderCellDef>Metrics</th>
              <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                {{element.value.metricName}}
              </td>
            </ng-container>
          
            <!-- low value -->
            <ng-container matColumnDef="lowValue">
              <!-- #R2-446 - bug fix -->
              <th mat-header-cell *matHeaderCellDef style="text-align: left;">Low Value</th>
              <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                <div class="form-field-wrapper">
                  <mat-form-field style="text-align: center;"
                    [appearance]="formGroup.get('tableForm')?.value[i].isEditable ? 'standard' : 'legacy'"
                    [ngClass]="{'no-border': !formGroup.get('tableForm')?.value[i].isEditable}">
                    <div class="create-program-flex-code">
                      <span *ngIf="!formGroup.get('tableForm')?.value[i].isEditable">
                        <</span>
                          <input matInput class="metric-value" type="text" formControlName="low"
                            [ngClass]="formGroup.get('tableForm')?.value[i].isEditable ? 'create-program-show-border' : 'create-program-hide-border'"
                            [readonly]="!formGroup.get('tableForm')?.value[i].isEditable">
                          <input class="create-program-code" type="text" formControlName="code" [readonly]="true">
                    </div>
                  </mat-form-field>
                  <mat-error class="table-form-error" *ngIf="getTableForm().controls[i]?.get('low')?.errors?.['required']">
                    Low value is required
                  </mat-error>
                  <mat-error class="table-form-error" *ngIf="getTableForm().controls[i]?.get('low')?.errors?.['pattern']">
                    Low value should be an integer
                  </mat-error>
                </div>
              </td>
            </ng-container>
          
            <!-- high value -->
            <ng-container matColumnDef="highValue">
              <!-- #R2-446 - bug fix -->
              <th mat-header-cell *matHeaderCellDef style="text-align: left;">High Value</th>
              <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                <div class="form-field-wrapper">
                  <mat-form-field style="text-align: center;"
                    [appearance]="formGroup.get('tableForm')?.value[i].isEditable ? 'standard' : 'legacy'"
                    [ngClass]="{'no-border': !formGroup.get('tableForm')?.value[i].isEditable}">
                    <div class="create-program-flex-code">
                      <span *ngIf="!formGroup.get('tableForm')?.value[i].isEditable">></span>
                      <input matInput class="metric-value" type="text" formControlName="high"
                        [ngClass]="formGroup.get('tableForm')?.value[i].isEditable ? 'create-program-show-border' : 'create-program-hide-border'"
                        [readonly]="!formGroup.get('tableForm')?.value[i].isEditable">
                      <input class="create-program-code" type="text" formControlName="code" [readonly]="true">
                    </div>
                  </mat-form-field>
                  <mat-error class="table-form-error" *ngIf="getTableForm().controls[i]?.get('high')?.errors?.['required']">
                    High value is required
                  </mat-error>
                  <mat-error class="table-form-error" *ngIf="getTableForm().controls[i]?.get('high')?.errors?.['pattern']">
                    High value should be an integer
                  </mat-error>
                </div>
              </td>
            </ng-container>
          
            <!-- action -->
            <ng-container *ngIf="!dialogData?.viewProgram" matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="text-align: right;">Action</th>
              <td mat-cell *matCellDef="let element; let i = index;" align="right"
                [formGroup]="element">
                <!-- edit -->
                <button mat-button *ngIf="!formGroup.get('tableForm')?.value[i].isEditable" (click)="onEditRow(formGroup, i)">
                  <mat-icon class="create-program-icon-display">edit</mat-icon>
                </button>
          
                <div class="create-program-row-action-cell">
                  <!-- save -->
                  <button mat-button *ngIf="formGroup.get('tableForm')?.value[i].isEditable" (click)="onSaveRow(formGroup, i)"
                    class="create-program-row-action-button">
                    <mat-icon class="create-program-icon-display create-program-save-button">done</mat-icon>
                  </button>
                  <!-- cancel -->
                  <button mat-button *ngIf="formGroup.get('tableForm')?.value[i].isEditable" (click)="onCancelRow(formGroup, i)"
                    class="create-program-row-action-button">
                    <mat-icon class="create-program-icon-display create-program-cancel-button">close</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </section>

        <section *ngIf="!dataSource.data.length" class="create-program-no-mat-table-data">
          No metrics added!
        </section>
      </ng-container>

      <!-- email -->
      <section class="email-container">
        <span class="mat-h4 create-program-input-label">Communication/Alert Emails *</span>
        <section class="email-chips-container" 
          [class.disabled]="dialogData?.viewProgram"
          [class.error]="(formGroup.get('email')?.touched && formGroup.get('email')?.hasError('required') && !emailList?.length) || formGroup.get('email')?.hasError('invalidEmail')">
          <mat-form-field class="program-rpm-input">
            <mat-chip-list #chipList formControlName="email">
              <mat-chip *ngFor="let item of emailList; let i=index" [selectable]="true" [removable]="true"
                (removed)="removeEmail(item)" required name="chips">{{item}}
                <mat-icon *ngIf="!dialogData?.viewProgram" matChipRemove>close</mat-icon>
              </mat-chip>
              <input [readonly]="dialogData?.viewProgram" [class.disabled]="dialogData?.viewProgram"
                placeholder="" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodesForEmailInput "
                [matChipInputAddOnBlur]="true " (matChipInputTokenEnd)="addEmail($event) " />
            </mat-chip-list>
          </mat-form-field>
        </section>
      </section>
      <mat-error *ngIf="formGroup.get('email')?.touched && formGroup.get('email')?.hasError('required') && !emailList?.length">
        Email is required.
      </mat-error>
      <mat-error *ngIf="formGroup.get('email')?.hasError('invalidEmail')">
        Email is invalid.
      </mat-error>
    </form>

    <!-- Dialog Actions -->
    <mat-dialog-actions [align]="'end'">
      <!-- for view mode -->
      <button *ngIf="dialogData?.viewProgram" mat-raised-button [mat-dialog-close]="true" class="program-rpm-button"
        color="primary">Close</button>
      <!-- for non view mode -->
      <button *ngIf="!dialogData?.viewProgram && !isPublished" mat-button color="primary"
        [disabled]="formGroup.invalid || !dataSource.data.length" class="program-rpm-button"
        (click)="onSaveAsDraft()">Save as
        Draft</button>
      <button *ngIf="!dialogData?.viewProgram" mat-button
        [disabled]="formGroup.invalid || !dataSource.data.length" class="program-rpm-button"
        (click)="onSaveAsPublish()">{{isPublished ? 'Save' : 'Publish'}}</button>
    </mat-dialog-actions>

  </ng-container>
</mat-dialog-content>