import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { DefaultDialogProperties, INACTIVE_DURATION } from '../config/app.config';
import { MsalService } from '@azure/msal-angular';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../models/matDialogData.model';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private inactivityTimer!: ReturnType<typeof setTimeout>;

  constructor(
    private auth: MsalService,
    private dialog: MatDialog
  ) { }

  public startInactivityTimer = (): void => {
    // Trigger when key is pressed in keyboard
    fromEvent(document, 'keydown').subscribe(() => this.resetTimer());
    // Trigger and check for mouse movements
    fromEvent(document, 'mousemove').subscribe(() => this.resetTimer());
    // reset the timer
    this.resetTimer();
  }

  private resetTimer = (): void => {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
    this.inactivityTimer = setTimeout(() => { this.logout() }, INACTIVE_DURATION);
  }

  private logout = (): void => {
    // #R2-595 - Added dialog for inactive logut
    this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>
    (ConfirmDialogComponent, {
      data: {
        confirmationHeader: `Inactive logout`,
        confirmationQuestion: `You are logged out due to inactivity. Please login to continue.`,
        okButton: true
      },
      ...DefaultDialogProperties,
    }).afterClosed().subscribe(() => {
      this.auth.logout({
        postLogoutRedirectUri:'/auth'
      });
    })
  }
}
